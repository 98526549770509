import React from 'react'
import blogClasses from '../../PMPblog.module.css'
import Container from '../../../../UI/Container/Container'
import Heading from '../../../../UI/Heading/Heading'
import posts from '../../../../../../data/blog/posts'
import SEO from '../../../../seo'
import Signature from '../Signature/Signature'
import B from '../../../../UI/B/B'

const post = posts.filter(post => post.link === 'moscow-method')[0]

export default () => (
  <Container width={1060}>
    <SEO
      title={`PMP Blog | ${post.title}`}
      keywords={[
        `Moscow Method`,
        `Requirements Prioritization`,
        `Dynamic Software Development Method`,
        `DSDM`
      ]}
      description="Requirements Prioritization Using MoSCoW Method, Determine Must Have Requirements for Your Project"
    ></SEO>
    <Heading text={post.title} />
    <div className={blogClasses.Image}>{post.images[0]}</div>
    <br />
    <p>
      <B data="MoSCoW" /> method has nothing to do with geography as the name
      might suggest. While managing a project it's important to understand the
      importance of each part of the work to be performed to keep to deadlines.{' '}
      <B data="MoSCoW" /> is a prioritization method used to decide which
      requirements to complete first, which must come later and which to
      exclude. <B data="MoSCoW" /> method originates from the dynamic software
      development method (DSDM).
      <br />
      The o's in the word <B data="MoSCoW" /> are added to make it easier to
      pronounce and memorize, and therefore are in lowercase to show they don't
      stand for anything.
    </p>
    <p>
      <B data="MoSCoW" /> stands for:
      <br />
      <B data="M" />
      ust have requirement to meet the business needs
      <br />
      o
      <br />
      <B data="S" />
      hould have requirements that are important but not vital
      <br />
      <B data="C" />
      ould have requirements that are desirable but less important
      <br />
      o
      <br />
      <B data="W" />
      on't have this time requirements
    </p>
    <div className={blogClasses.SubTitleContainer}>
      <h2 className={blogClasses.SubTitle}>Must have requirement</h2>
    </div>
    <div className={blogClasses.List}>
      <ul className={blogClasses.NoBottomMargin}>
        <li>Non-negotiable</li>
        <li>Not legal/unsafe without it</li>
        <li>Cannot deliver without it</li>
      </ul>
    </div>
    <p>
      Failure to deliver even one of them will likely mean the project has
      failed. If the answer to the question 'what happens if this requirement is
      not met?' is 'to cancel the project' then this is a must have requirement.
      If there is some way around it, even if it is a painful workaround, then
      it is a Should have or a Could have requirement.
    </p>
    <div className={blogClasses.SubTitleContainer}>
      <h3 className={blogClasses.SubTitle}>Should have requirement</h3>
    </div>
    <div className={blogClasses.List}>
      <ul className={blogClasses.NoBottomMargin}>
        <li>May need some workaround</li>
        <li>Important but not vital</li>
        <li>May be painful to leave out but the solution is still viable</li>
      </ul>
    </div>
    <p>
      Should haves are important to the project or release, but they are not
      vital. If left out, the project still functions. However, if they are
      included, they add significant value. One way to tell a Should have
      requirement from a Could have requirement is by reviewing the degree of
      pain caused by the requirement not being met, measured in terms of
      business value or numbers of people affected. The project team should aim
      to deliver as many of the Should requirements as possible.
    </p>
    <div className={blogClasses.SubTitleContainer}>
      <h4 className={blogClasses.SubTitle}>Could have requirement</h4>
    </div>
    <div className={blogClasses.List}>
      <ul className={blogClasses.NoBottomMargin}>
        <li>Desirable but less important</li>
        <li>Less impact if left out (compared with a Should have)</li>
      </ul>
    </div>
    <p>
      When the deadline is at risk or the budget comes under pressure, one or
      more of the Could have requirements are the first choice of what is to be
      dropped out.
    </p>
    <div className={blogClasses.SubTitleContainer}>
      <h5 className={blogClasses.SubTitle}>Won't have requirement</h5>
    </div>
    <div className={blogClasses.List}>
      <ul className={blogClasses.NoBottomMargin}>
        <li>Not a priority for this specific timeframe</li>
        <li>Nice to have but has no real impact</li>
        <li>Get back to them at better days</li>
      </ul>
    </div>
    <p>
      Some initiatives in the 'will-not-have' group will get prioritized in the
      future, while others are not likely to happen at all. Some teams decide to
      differentiate between those by creating a subcategory within this group.
    </p>
    <br />
    <div className={blogClasses.SubTitleContainer}>
      <h6 className={blogClasses.SubTitle}>Where to start</h6>
    </div>
    <p>
      Before using the <B data="MoSCoW" /> method, you must ensure the teams
      involved in the project and other stakeholders agree on the project goals
      and the factors that will be used for prioritization. Teams should also
      discuss how they will settle any disagreements in prioritization.
      <br />
      Finally, you'll also want to reach a consensus on what percentage of
      resources you'd like to allocate to each category.
      <br />
      After the groundwork is complete, you can begin determining which category
      is most appropriate for each initiative.
    </p>
    <p>
      As a software developer I have used <B data="MoSCoW" /> method multiple
      times and can define the following pros and cons:
    </p>
    <span>Pros:</span>
    <div className={blogClasses.List}>
      <ul className={blogClasses.NoBottomMargin}>
        <li>Simplicity</li>
        <li>Agility for scheduling and implementation</li>
      </ul>
    </div>
    <span>Cons:</span>
    <div className={blogClasses.List}>
      <ul className={blogClasses.NoBottomMargin}>
        <li>
          Does not provide the order in which requirements are to be implemented
          within the individual categories
        </li>
        <li>Not effective if you have a complicated backlog</li>
      </ul>
    </div>
    <div className={blogClasses.Image} style={{ marginTop: 10, width: '50%' }}>
      {post.images[1]}
    </div>
    <br />
    <div className={blogClasses.SubTitleContainer}>
      <h6 className={blogClasses.SubTitle}>Conclusion</h6>
    </div>
    <p>
      <B data="MoSCoW" /> method is very effective when prioritizing work for
      small and not too complex products that do not have many technical
      limitations. Prioritizing work using <B data="MoSCoW" /> is fast and
      transparent and is easy to master and use.
    </p>
    <Signature date={post.date} author={post.author} />
  </Container>
)
